import React from "react";
import Img from "gatsby-image";
import { css } from "@emotion/core";
import { graphql } from "gatsby";
import Title from "../components/title";
import Layout from "../layout/site-layout";
import Return from "../components/return-button";
import SEO from "../components/seo";

export const query = graphql`
  query {
    image1: file(name: { eq: "insp_01" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image2: file(name: { eq: "insp_02" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image3: file(name: { eq: "insp_03" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Inspiration = ({ data }) => (
  <Layout>
    <SEO title="Inspiration" />
    <Title text="Inspiration" />
    <div
      css={css`
        display: grid;
        grid-gap: 1rem;
        margin-top: 1rem;

        @media screen and (min-width: 768px) {
          grid-gap: 2rem;
          margin-top: 2rem;
        }
      `}
    >
      <Img fluid={data.image1.childImageSharp.fluid} />
      <Img fluid={data.image2.childImageSharp.fluid} />
      <Img fluid={data.image3.childImageSharp.fluid} />
    </div>
    <Return />
  </Layout>
);

export default Inspiration;
