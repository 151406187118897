import React from "react";
import { css } from "@emotion/core";

const Title = ({ text }) => (
  <h1
    css={css`
      padding-top: 2rem;
      font-size: 3.6rem;
    `}
  >
    {text}
  </h1>
);

export default Title;
