import React from "react";
import { css } from "@emotion/core";
import { useIntl, Link } from "gatsby-plugin-intl";

const Return = () => {
  const intl = useIntl();

  return (
    <>
      <Link
        to="/"
        css={css`
          position: relative;
          display: inline-block;
          text-decoration: none;
          font-weight: 600;
          margin: 20px 0;

          &:hover {
            > div {
              height: 115%;
            }
          }
        `}
      >
        <div
          css={css`
            position: absolute;
            bottom: -2px;
            left: -2px;
            width: 110%;
            height: 1rem;
            background: rgba(34, 112, 147, 0.2);
            transition: height 0.2s ease;
          `}
        ></div>
        <p>{intl.formatMessage({ id: "back" })}</p>
      </Link>
    </>
  );
};

export default Return;
